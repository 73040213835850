import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
// import Header from '../containers/layout/header/header-three'
// import Header from '../containers/layout/header/header-four'
import Header from '../containers/layout/header/header-three'
import HeroArea from '../containers/index-services/hero-area'
import AboutArea from '../containers/index-services/about-area'
import WebinarsArea from '../containers/index-services/webinars-area'
import ServicesArea from '../containers/index-services/services-area'
import GradationArea from '../containers/index-services/gradation-area'
import CtaArea from '../containers/global/cta-area/section-one'
import ContactArea from '../containers/global/contact-area/contact-four'

import AboutServiceWrap from '../containers/index-infotechno/about-service-wrap'
import ServicesAreaFeatures from '../containers/index-infotechno/services-area'
// import AboutArea from '../containers/index-infotechno/about-area'


const IndexServices = ({ location }) => (
  <Layout location={location}>
    <SEO />
    <Header transparent />
    <main className="site-wrapper-reveal">
      <HeroArea />
      <WebinarsArea />

      <AboutServiceWrap>
        <AboutArea />
        <ServicesAreaFeatures />
      </AboutServiceWrap>

      <ServicesArea />


      {/* <Features /> */}

      <CtaArea />


      <GradationArea />

      <ContactArea />

    </main>

  </Layout>
)

export default IndexServices
